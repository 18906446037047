<template>
  <v-sheet class="pa-1">
    <v-skeleton-loader class="mx-auto" max-width="300" type="card">
      <v-card class="mx-auto mb-3" color="grey lighten-5" flat>
        <v-card-title class="primary--text">
          <slot name="title"/>
        </v-card-title>
        <v-card-text>
          <slot name="content"/>
        </v-card-text>
      </v-card>
    </v-skeleton-loader>
  </v-sheet>
</template>

<script>
export default {
  name: 'SettingsCard'
};
</script>

<style></style>
